import React from 'react';
import './Projects.css'

const projects = [
  {
    id: 1,
    name: 'BrightCareers.in',
    imageUrl: '/projects/6.png',
    manager: 'Prandeep',
    link: 'https://brightcareers.in',
    developers: ['Prandeep']
  },
  // {
  //   id: 2,
  //   name: 'Project B',
  //   imageUrl: 'https://example.com/projectB.jpg',
  //   manager: 'Jane Smith',
  //   developers: ['Pqr', 'Def', 'LMN']
  // },

];

const ProjectCard = ({ project }) => {
  return (
   <section className='projects'>
     <div className="project-card">
      <img src={project.imageUrl} alt={project.name} className="project-image" />
      <div className="project-details">
        <h3>{project.name}</h3>
        <p>Project Managed By: {project.manager}</p>
        <p>Developers: {project.developers.join(', ')}</p>
        <a href={project.link} className="view-project-btn" target='_blank' rel="noreferrer">View Project</a>
      </div>
    </div>
   </section>
  );
};

const ProjectPage = () => {
  return (
    <div className="project-page">
      {projects.map(project => (
        <ProjectCard key={project.id} project={project} />
      ))}
    </div>
  );
};

export default ProjectPage;
