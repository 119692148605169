// SoftwareSolutionsSection.js
import React from 'react';
import './Softwares.css';

const SoftwareSolutionsSection = () => {
  const solutions = [
    {
      icon: '🚀',
      title: 'Lead Generator',
      description: 'Generate leads effortlessly with our powerful lead generation tool.',
    },
    {
      icon: '💬',
      title: 'Personalized Chatbot',
      description: 'Enhance customer engagement with our intelligent and personalized chatbot.',
    },
    {
      icon: '💬',
      title: 'Marketing Automation',
      description: 'Email automation, Social media scheduling and posting, Customer segmentation and targeted campaigns',
    },

  ];

  return (
    <section className="software-solutions-section">
      <h2>Our Software Solutions</h2>
      <div className="solutions-container">
        {solutions.map((solution, index) => (
          <div className="solution-card" key={index}>
            <div className="solution-icon">{solution.icon}</div>
            <h3>{solution.title}</h3>
            <p>{solution.description}</p>
            <button className="explore-button">Explore</button>
          </div>
        ))}
      </div>
      {/* <button className='explore-button'>Explore More</button> */}
    </section>
  );
};

export default SoftwareSolutionsSection;
