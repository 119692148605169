import React from 'react';
import './WebsitePricing.css';
import { Link } from 'react-router-dom';

const Pricing = () => {
  return (
    <div className="pricing-page">
      <div className="pricing-heading">
        <h2>Website Development Pricing</h2>
        <p>
          We deliver smooth, superfast websites with secure backends. Our professional admin dashboard makes website management easy, requiring no tech skills.
        </p>
      </div>
      
      <div className="pricing-table">
        <h3>Website Types and Pricing (In Rupees)</h3>
        <table>
          <thead>
            <tr>
              <th>Website Type</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>E-Commerce Website</td>
              <td>10,000</td>
            </tr>
            <tr>
              <td>Customer Relationship Management Software</td>
              <td>25,000</td>
            </tr>
            <tr>
              <td>Showcasing Website</td>
              <td>5,000</td>
            </tr>
            <tr>
              <td>Portfolio Website</td>
              <td>2,000</td>
            </tr>
            <tr>
              <td>Customized Web-Application</td>
              <td>25,000</td>
            </tr>
            {/* Add more rows for different types of websites */}
          </tbody>
        </table>
      </div>
      
      <Link to="/contact" className='contact-button'>
          Contact Now
        </Link>
    </div>
  );
};

export default Pricing;
